module.exports = {
  apiEndpoint: process.env.GATSBY_API_URL,
  apiPlatformId: process.env.GATSBY_API_PLATFORM_ID,
  apiDeliveryId: process.env.GATSBY_API_DELIVERY_ID,
  siteTitle: process.env.GATSBY_APP_NAME, // Site title.
  siteTitleAlt: "Vivigratis: offerte e consigli d'acquisto", // Alternative site title for SEO.
  siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
  publisherLogo: "/logos/logo-publisher.png",
  siteUrl: process.env.GATSBY_APP_URL, // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription:
    "Offerte e consigli d'acquisto selezionati rispetto a moda, tempo libero, tecnologia, benessere, bellezza, casa e famiglia. Fatti consigliare, confronta e scegli!", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  siteFBAppID: "1920273098003588", // FB Application ID for using app insights
  googleAnalyticsID: process.env.GATSBY_GA, // GA tracking ID.
  disqusShortname: "", // Disqus shortname.
  postDefaultCategoryID: "", // Default category for posts.
  userName: "Vivigratis SA", // Username to display in the author segment.
  userTwitter: "", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "", // User location to display in the author segment.
  userAvatar: "", // User avatar to display in the author segment.
  userDescription: "", // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      label: "GitHub",
      url: "",
      iconClassName: "fa fa-github"
    },
    {
      label: "Twitter",
      url: "",
      iconClassName: "fa fa-twitter"
    },
    {
      label: "Email",
      url: "",
      iconClassName: "fa fa-envelope"
    }
  ],
  copyright: "2019 Vivigratis SA", // Copyright string for RSS feed.  
  themeColor: "#005bea", // Used for setting manifest and progress theme colors.
  backgroundColor: "#ffffff", // Used for setting manifest background color.
  themes: {
    default: {
      main: "#005bea",
      secondary: "#00c6fb"
    },
    viaggi: {
      main: "#209cff",
      secondary: "#68e0cf"
    },
    moda: {
      main: "#ff1800",
      secondary: "#ff6666"
    },
    "benessere-bellezza": {
      main: "#7873f5",
      secondary: "#ec77ab"
    },
    "tempo-libero": {
      main: "#39ad42",
      secondary: "#d0e743"
    },
    "casa-famiglia": {
      main: "#28c5c6",
      secondary: "#01e6a2"
    },
    tecnologia: {
      main: "#ff0844",
      secondary: "#ffb199"
    },
    hot: {
      main: "#ed73ff",
      secondary: "#5b94ff"
    }
  }
};
